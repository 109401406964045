<template>
  <div class="about">
    <CCard style="width: 100%">
      <CCardBody>
    <h1>{{ $t("guide_l1") }}</h1>

    <h2>{{ $t("guide_l2") }}</h2>

    <h3>{{ $t("guide_l3") }}</h3>

    <div>
      <div>{{ $t("guide_l4") }}</div>
      <ul v-html="$t('guide_l5')"></ul>
    </div>
    <div>
      <div>{{ $t("guide_l6") }}</div>
      <ul v-html="$t('guide_l7')"></ul>
    </div>
    <div>
      <div>{{ $t("guide_l8") }}</div>
      <ul>
        <li>{{ $t("guide_l9") }}</li>
      </ul>
    </div>

    <h2>{{ $t("guide_l10") }}</h2>

    <h3>{{ $t("guide_l12") }}</h3>

    <div>
      <div>{{ $t("guide_l13") }}</div>
      <ul v-html="$t('guide_l14')"></ul>
    </div>
    <div>
      <div>{{ $t("guide_l15") }}</div>
      <ul>
        <li>{{ $t("guide_l16") }}</li>
      </ul>
    </div>
    <h2>{{ $t("guide_l17") }}</h2>

    <h3>{{ $t("guide_l18") }}</h3>
  </CCardBody>
  </CCard>  
  </div>
  
</template>

<script>


export default {
  name: 'About',
  
}
</script>

<style scoped>
  h2{
    margin-top: 40px;
  }
  h3{
    font-size: 1.3rem !important;
    margin-bottom: 20px;
    margin-top: 10px;
  }
</style>
